import React from 'react'

const GoogleMapsIframe = () => {
  return (
    <iframe
      src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3138.0694323396838!2d176.24665537644302!3d-38.138578471896025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d6c277b04053cc7%3A0x7804da96d6b7a3f0!2s1241%20Eruera%20Street%2C%20Rotorua%203010!5e0!3m2!1sen!2snz!4v1736712714592!5m2!1sen!2snz'
      width='600'
      height='450'
      style={{ border: 0 }}
      allowFullScreen=''
      loading='lazy'
      referrerPolicy='no-referrer-when-downgrade'
      title='Google Maps - 1241 Eruera Street, Rotorua'
    />
  )
}

export default GoogleMapsIframe
